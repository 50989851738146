$( document ).ready(function() {

    /*Patička otevírání odkazů + ošetření při změně velikosti*/
    $('.nadpis').click(function(event){
        var windowWidth = $(window).width();
        if(windowWidth < 850){
            $("ul", this).slideToggle();
            $("span", this).text(function(i, text){
                return text === "+ více" ? "- zavři" : "+ více";
            })
        }
    });
    $(window).on('resize', function(event){
        var windowCurrentWidth = $(window).width();
        if(windowCurrentWidth > 850){
            $(".nadpis ul").show();
        }
        else{
            $(".nadpis ul").hide();
            $(".nadpis span").text("+ více");
        }
    });

    $(".owl-carousel-main").owlCarousel({
        items:1,
        autoplay:true,
        loop:true,
        nav:true,
        dots:true,
        responsiveClass:true,
        responsive:{
            0:{
                dots:false,
            },
            400:{
            }
        }
    });

    $(".owl-carousel-HPsmall").owlCarousel({
        items:2,
        nav:true,
        autoplay:true,
        loop:true,
        margin:10,
        dots:false,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            500:{
            }
        }
    });

    $(".owl-carousel-loga").owlCarousel({
        items:6,
        autoplay:true,
        nav:true,
        margin: 80,
        autoWidth:true,
        loop:true,
        dots:false,
        responsiveClass:true,
        responsive:{
            0:{
                items: 1,
                margin: 20,
                autoWidth:false,
            },
            450:{
                items:2,
            },
            800:{
                items:3,
            },
            1201:{
                items:5,
                nav:true,
            }
        }
    });

    $('#hamburger').click(function(event){
        $(".responsive-menu").show(300);
        $("body").css("overflow-y","hidden");
    });

    $('#close-cross').click(function(event){
        $(".responsive-menu").hide(300);
        $("body").css("overflow-y","scroll");
    });

    // function moveMenu(x) {
    //     var tagMenu = $("#tag-menu");
    //     var burger = $("#hamburger");
    //     var menuStrip = $(".menu-strip ul");
    //     var header = $("header");
    //
    //     if (x.matches) {
    //         menuStrip.insertBefore(header, null);
    //         tagMenu.addClass("responsive-menu");
    //     } else {
    //         tagMenu.insertBefore(burger, null);
    //         tagMenu.removeClass("responsive-menu");
    //         tagMenu.show(300);
    //     }
    // }
    // var x = window.matchMedia("(max-width: 950px)");
    // moveMenu(x);
    // x.addListener(moveMenu);
});